import React from "react";
import PureComponent from "../pure";

import Text from "../formElements/InputText";

import sAction from "sAction";

class ListViewSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.state = {
      inputVal: "",
      searchedInputVal: ""
    }
  }

  search() {
    const val = this.input.current.value;
    const prefix = this.props.prefix;
    sAction.listGlobalSearch(val, prefix);
    this.setState({ searchedInputVal: val });
  }

  newRecord() {
    const params = {
      module: this.props.module,
      way:this.props.prefix
    }
    sAction.detailNewRecord(params)
  }

  componentDidMount() {
    this.setDefaultValue();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.module !== prevProps.module || this.props.data !== prevProps.data) {
      this.setDefaultValue();
    }
  }

  clearInputValue = () => {
    this.setState({inputVal: ""});
  }

  setDefaultValue = () => {
    const data = this.props.data;
    let defaultValue = "";

    if (data.toJS().length > 0) {
      data.forEach((filter, index) => {
        if (filter.field === "*" && filter.filters) {
          defaultValue = filter.filters.toJS()[0].value;
        }
      });
    }

    this.setState({inputVal: defaultValue, searchedInputVal: defaultValue});
  }

  onInputChange = (e) => {
    const input = e.target.value;
    this.setState({inputVal: input});
  }

  onClearButton = () => {
    this.clearInputValue();
    if (this.state.searchedInputVal) {
      // zabrani zbytecnemu vyhledavani, kdyz je input prazdny nebo smazany
      this.search();
    }
  }

  render() {
    const noNewRecordModules = ['acm_invoices', 'acm_orders'];
    return (
      <div className="flexInLine flexVerticalCenter">
        <div className="listViewSearch">
          <div
            onClick={() => this.search()}
            className="icon-search listViewSearchIcon"
          />
          <Text
            myRef={this.input}
            onKeyUp={event => event.keyCode == 13 && this.search()}
            placeholder={sAction.translate("LBL_LIST_SEARCH_GLOBAL_DESC")}
            autoFocus={true}
            clearButton={true}
            onChange={this.onInputChange}
            value={this.state.inputVal}
            onClearButton={this.onClearButton}
          />
          <div className="listViewSearchButton" onClick={() => this.search()}>
            {sAction.translate("LBL_LIST_SEARCH_GLOBAL")}
          </div>

        </div>
        {!noNewRecordModules.includes(this.props.module) && (
          <div className="listViewSearchButtonAdd" onClick={() => this.newRecord()}>
            <div className="icon-addIcon">

            </div>
          </div>)}
      </div>
    );
  }
}
export default ListViewSearch;
