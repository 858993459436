import detailDefault from "../../detailDefault";
/**
 *  Dárky wiki https://gitlab.acmark.cz/coripo/coripo/-/wikis/funkcionality-a-procesy/nove-darky
 */
export default class detailacm_gifts_planning extends detailDefault {
    load(sAction, data) {
        const giftStockId = sAction.dataGet( data.prefix + "/fields/acm_gifts_planning_acm_giftsstock_name/def/id_value");
        sAction.dsClear();
        if(giftStockId && sAction.dataGet("view/id")){
            sAction.dsAdd("set", data.prefix + "/fields/acm_gifts_planning_acm_giftsstock_name/def/readonly", true)
        } else {
            sAction.dsAdd("set", data.prefix + "/fields/acm_gifts_planning_acm_giftsstock_name/def/readonly", false)
        }
        // pri kopirovani plau vynulovat pole
        if(!sAction.dataGet("view/id")){
            sAction.dsAdd("set", data.prefix + "/fields/darku_muze_rozdat/value", 0)
            sAction.dsAdd("set", data.prefix + "/changes/fields/darku_muze_rozdat", 0)
            sAction.dsAdd("set", data.prefix + "/fields/darku_rozdal/value", 0)
            sAction.dsAdd("set", data.prefix + "/changes/fields/darku_rozdal", 0)
            sAction.dsAdd("set", data.prefix + "/fields/darku_zbyva/value", 0)
            sAction.dsAdd("set", data.prefix + "/changes/fields/darku_zbyva", 0)
        }
        sAction.dsProcess();

        const defaultFilter = [
            {
                module: "acm_GiftsStock",
                field: "darku_celkem",
                value: 0,
                type: "moreThan",
            },
            {
                module: "acm_GiftsStock",
                field: "active",
                value: "1",
                type: "eq",
            },
            // orGroup id: 1
            {
                module: "acm_gifts_planning",
                field: "datum_zamknuti",
                type: "moreThanInclude",
                value: "{%DATE_TODAY%}",
                orGroup: 1
            },
            {
                module: "acm_gifts_planning",
                field: "datum_zamknuti",
                type: "null",
                value: null,
                orGroup: 1
            }
        ];

        sAction.dsClear()
        sAction.dsAdd("set", data.prefix + "/fields/acm_gifts_planning_acm_giftsstock_name/def/defaultFilter", defaultFilter);
        sAction.dsProcess();
    }

    update(sAction, data) {
        switch (data.field) {
            case "darku_muze_rozdat":
                this.checkAvailability(sAction, data);
                const giftsToGive = sAction.dataGet( data.prefix + "/fields/darku_muze_rozdat/value");
                const giftsGiven = sAction.dataGet( data.prefix + "/fields/darku_rozdal/value");
                if((giftsGiven === null || giftsGiven === 0) && giftsToGive){
                    sAction.dsClear();
                    sAction.dsAdd("set", data.prefix + "/fields/darku_zbyva/value", giftsToGive)
                    sAction.dsAdd("set", data.prefix + "/fields/darku_rozdal/value", 0)
                    sAction.dsProcess();
                }
                break;
            case "acm_gifts_planning_acm_giftsstockacm_giftsstock_ida":
                this.checkAvailability(sAction, data);
                break;
        }
    }

    checkAvailability = (sAction, data) => {
        const giftStockId = sAction.dataGet( data.prefix + "/fields/acm_gifts_planning_acm_giftsstock_name/def/id_value");
        const count = sAction.dataGet( data.prefix + "/fields/darku_muze_rozdat/value");

        if(!giftStockId || !count){
            return;
        }
        let reqData = {
            gift_id: giftStockId,
            count: count,
        }
        sAction.load();
        sAction.rest.post("checkGiftStockAvailability", reqData, (resp) => {
            sAction.unLoad();
            if(!resp.status){
                sAction.error(resp.errorMessage.text);
                sAction.setStorage("canSave", false);
            } else {
                sAction.setStorage("canSave", true);
            }
        });
    }

    beforeSave(sAction, data) {
        if (sAction.getStorage("canSave")) {
            return true;
        }
        this.checkAvailability(sAction, data);
    }
}