export default function checkICO(ico) {
  const module = this.dataGet("conf/page");

  const returnMessage=(data)=>{
    let msg = 
    "<p>" +
    this.translate("LBL_DUPCHECK_MSG_IC_FOUND", `${data.module}`) +
    "<br /><br />"+
    this.translate("LBL_DUPCHECK_COMPANY_NAME", `${data.module}`) +
    ": <b>" +
    data.name +
    "</b><br />" +
    this.translate("LBL_DUPCHECK_COMPANY_ADDR", `${data.module}`) +
    ": <b>" +
    (data.billing_address_street ? data.billing_address_street + ", " : "") +
    (data.billing_address_city ? data.billing_address_city + ", " : "") +
    (data.billing_address_postalcode ? data.billing_address_postalcode : "") +
    "</b> <br /><br />" +
    // Chcete přejít na tento záznam?
    this.translate("LBL_GO_TO_RECORD_CONFIRM", `${data.module}`) +
    "</p>";

    return msg;
  }

  this.rest.post("detailapi/checkico", { ico, module }, data => {
    if (data.status === "found") {
      if (data.id !== this.getRecordId()) {
 
        this.confrim(returnMessage(data), () => {
          this.popupHide();
          window.open("#detail/"+data.module+"/" + data.id);
        });
      }
    }
  });
}