import detailDefault from "../../detailDefault";

export default class acm_GiftsStock extends detailDefault {
  load(sAction, data) {
    let siAdmin = sAction.dataGet("conf/user/is_admin") === "1";
    if(siAdmin){
      sAction.dsClear();
      sAction.dsAdd('set', data.prefix + '/fields/datum_zamknuti/def/readonly', false);
      sAction.dsProcess();
    }
  }

  update(sAction, data) {
  }
}