export default function quickSearch(paramData, callback = null) {
  if (paramData.getFields == undefined) {
    paramData.getFields = [];
  }
  if (paramData.module == undefined || paramData.module == "") {
    console.log("není zadán modul");
    return;
  }
  if (paramData.value == undefined || paramData.value == "") {
    console.log("není zadána vyhledávácí hodnota");
    return;
  }
  if (paramData.fields == undefined || paramData.fields == "") {
    console.log("nejsou zadány pole, ve kterých vyhledávat [Array]");
    return;
  }

  let order = paramData.fields[0];
  const asc = "ASC";

  var filters = [];
  if (
    (paramData.module == "Users" || paramData.module == "Contacts" || paramData.module == 'Leads') &&
    paramData.fields[0] == "name"
  ) {
    // TODO: vychozi order zde nefunguje - databazova chyba
    // tohle je jen docasny fix
    order = "last_name";

    const parseValue = paramData.value.split(" ");
    if (parseValue.length > 1) {
      filters.push(
        {
          operator: "and",
          operands: [
            { field: "first_name", type: "cont", value: parseValue[0] },
            { field: "last_name", type: "cont", value: parseValue[1] }
          ]
        },
        {
          operator: "and",
          operands: [
            { field: "last_name", type: "cont", value: parseValue[0] },
            { field: "first_name", type: "cont", value: parseValue[1] }
          ]
        },
        {
          operator: "and",
          operands: [
            {field: "*", type: "cont", value: paramData.value}
          ]
        }
      );
    } else {
      filters.push({
        field: "first_name",
        type: "cont",
        value: paramData.value
      });
      filters.push({
        field: "last_name",
        type: "cont",
        value: paramData.value
      });
    }
  } else {
    paramData.fields.forEach(element => {
      filters.push({
        field: element,
        type: "cont",
        value: paramData.value
      });
    });
  }

  let addCustomFilter = {
    operator: "or",
    operands: filters
  };

  let filter = this.parseDefaultFilter(paramData.defaultFilter, addCustomFilter);

  const searchData = {
    limit: paramData['limit'] ? paramData['limit'] : 50,
    offset: 0,
    order: [{
      field: order,
      sort: "asc"
    }],
    filter: filter
  };

  if (paramData.getFields !== undefined) {
    var columns = {};
    paramData.getFields.forEach(field => {
      columns[field] = {field : field};
    });

    searchData.columns = columns;
  }

  this.rest.post("list/" + paramData.module, searchData, data => {
    const records = data.records;
    var returnRecords = [];
    var returnFields = paramData.getFields;
    /*if (returnFields.length == 0) {
      returnFields = paramData.fields;
    }
    */
    if(records !== undefined){
    records.forEach(record => {
      var newRecord = {};
      returnFields.forEach(field => {
        newRecord[field] = record[field];
      });
      returnRecords.push(newRecord);
    });
    if (callback != null) {
      callback(returnRecords);
    }
    }
  },false);
}
