import detailDefault from "../../detailDefault";
/**
 *  Dárky wiki https://gitlab.acmark.cz/coripo/coripo/-/wikis/funkcionality-a-procesy/nove-darky
 */
export default class detailacm_gift_requests extends detailDefault {
    load(sAction, data) {
        let isSuperUser = sAction.dataGet(`conf/user/is_superUser`)
        let isAdmin = sAction.dataGet(`conf/user/is_admin`)

        if ((isSuperUser === 1 || isSuperUser === '1') || (isAdmin === 1 || isAdmin === '1')) {
            sAction.dsClear()
            sAction.dsAdd("set", data.prefix + "/fields/gift_plan_id/def/readonly", false);
            sAction.dsAdd("set", data.prefix + "/fields/gift_plan_name/def/readonly", false);
            sAction.dsAdd("set", data.prefix + "/fields/request_state/def/readonly", false);
            sAction.dsAdd("set", data.prefix + "/fields/gifts_requested/def/readonly", false);
            sAction.dsAdd("set", data.prefix + "/fields/request_gift_id/def/readonly", false);
            sAction.dsAdd("set", data.prefix + "/fields/request_gift_name/def/readonly", false);
            sAction.dsProcess();
        }

        let requestState = sAction.dataGet(`${data.prefix}/fields/request_state/value`)
        if (requestState === "assigned") {
            sAction.dsClear()
            sAction.dsAdd("set", data.prefix + "/fields/gift_plan_id/def/required", true);
            sAction.dsAdd("set", data.prefix + "/fields/gift_plan_name/def/required", true);
            sAction.dsProcess();
        }
        if (requestState !== "requested") {
            sAction.dsClear()
            sAction.dsAdd("set", data.prefix + "/fields/gifts_requested/def/readonly", true);
            sAction.dsAdd("set", data.prefix + "/fields/request_gift_id/def/readonly", true);
            sAction.dsAdd("set", data.prefix + "/fields/request_gift_name/def/readonly", true);
            sAction.dsProcess();
        }
    }

    update(sAction, data) {
        switch (data.field) {
            case "request_state":
                if (data.value === "assigned"){
                    sAction.dsClear()
                    sAction.dsAdd("set", data.prefix + "/fields/gift_plan_id/def/required", true);
                    sAction.dsAdd("set", data.prefix + "/fields/gift_plan_name/def/required", true);
                    sAction.dsProcess();
                }
                break;
            case "gift_plan_id":
                console.log(data, "id");
                if (data.value.id && data.value.id !== ""){
                    sAction.dsClear()
                    sAction.dsAdd("set", data.prefix + "/fields/request_state/value", "assigned");
                    sAction.dsAdd("set", data.prefix + "/changes/fields/request_state", "assigned");
                    sAction.dsProcess();
                }
                break;
           
        }
    }

}