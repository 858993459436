import {date} from "ROOT/src/components/listEditableFields/exportConfig";

export default function taskFromMeeting(recordData) {

  let postData = {
    action: "taskFromMeeting",
    record: recordData.id
  }

  this.load()

  this.rest.post("customAction", postData, returnData => {
    if (returnData.errCode === "OK") {
      this.href("#detail/Tasks/" + returnData.record);
    } else {
      this.unLoad()
      this.alert(returnData.errMsg)
    }
  });
}
