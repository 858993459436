import detailDefault from "../../detailDefault";

export default class detailAccounts extends detailDefault {
  load(sAction, data) {
    this.updateDetailApiVisibility(sAction, data);
    
    let req =  sAction.dataGet(data.prefix + "/fields/parent_id/value") ? false: true;
    sAction.dataSet(data.prefix+"/fields/assigned_user_name/def/required",  req);
    sAction.dataSet(data.prefix+"/fields/assigned_user_id/def/required",  req);

    let sa_company = sAction.dataGet(data.prefix +"/fields/as_company/value") 
    this.updateEndCustomer(sAction, data);
    sAction.dsClear();
    sAction.dsAdd("set", data.prefix+"/fields/seeky_activity/def/type", "HTMLText");
    sAction.dsAdd("set", data.prefix+"/fields/seeky_activity/def/readonly", true);

    if (sa_company == "^all^"){
      sAction.dsAdd("set", data.prefix + "/customData/dashboardVersion", "all");
    } else {
      sAction.dsAdd("set", data.prefix + "/customData/dashboardVersion", "simple");
    }

    sAction.dsProcess();
  }

  update(sAction, data) {
    switch (data.field) {
      case "billing_address_country":
        this.updateDetailApiVisibility(sAction, data)
        break;
      case "lastname":
      case "firstname":
      case "end_customer":
        this.updateEndCustomer(sAction, data);
        break;
      case "parent_id":
        let req = data.value.name ? false: true;
        sAction.dataSet(data.prefix+"/fields/assigned_user_name/def/required",  req);
        sAction.dataSet(data.prefix+"/fields/assigned_user_id/def/required",  req);
        break;
    }
  }

  beforeSave(sAction, data) {
    if (sAction.dataGet(data.prefix + "/customData/beforeSaveCheckRes")){
      sAction.dataSet(data.prefix + "/customData/beforeSaveCheckRes", false)
      return true;
    }

    const searchData = {
      ico: sAction.dataGet(data.prefix + "/fields/ticker_symbol/value"),
      name: sAction.dataGet(data.prefix + "/fields/name/value"),
      id: sAction.dataGet(data.prefix + "/id"),
    };
    

    sAction.rest.post("checkAccDupli", searchData, returnData => {
      if (returnData > 0 && (searchData.ico!=="" || searchData.ico==null) ){
        sAction.alert(sAction.translate('LBL_ICO_OR_NAME_EXISTS'))
        return false;
      }
      else{
        sAction.dataSet(data.prefix + "/customData/beforeSaveCheckRes", true)
        const saveRecordButtons = document.querySelectorAll("#save_record");
        saveRecordButtons[saveRecordButtons.length - 1].click();
      }
    }, false);
    return false;
  }
  /**
   * @brief Funkcia updatuje polia podla toho či je uživatel end_customer 
   *        ak je end_customer nastaví pole na Název na Jméno+Příjmení
   * @param {*} sAction 
   * @param {*} data 
   */
  updateEndCustomer(sAction, data)
  {
    const end_customer=   Boolean(data.field=="end_customer" ? data.value :  parseInt(sAction.dataGet(data.prefix + "/fields/end_customer/value")));
    sAction.dsClear();
    sAction.dsAdd("set", data.prefix+"/fields/ticker_symbol/def/required", !end_customer );
    sAction.dsAdd("set", data.prefix+"/fields/firstname/def/required", end_customer );
    sAction.dsAdd("set", data.prefix+"/fields/firstname/def/visible", end_customer );
    sAction.dsAdd("set", data.prefix+"/fields/lastname/def/required", end_customer );
    sAction.dsAdd("set", data.prefix+"/fields/lastname/def/visible", end_customer );
    if(end_customer){
      const firstname=sAction.dataGet(data.prefix + "/fields/firstname/value");
      const lastname=sAction.dataGet(data.prefix + "/fields/lastname/value");
      if(firstname && lastname){
        sAction.dsAdd("set", data.prefix+ "/fields/name/value", `${firstname} ${lastname}`);
        sAction.dsAdd("set", data.prefix + "/changes/fields/name", `${firstname} ${lastname}`);
      }
    }
    sAction.dsProcess();
  }


  //fce skryva/odkryva detail fieldy podle zeme, jestli je zeme CZECH REPUBLIC
  updateDetailApiVisibility(sAction, data) {
    let address;
    if (data.value) {
      address = data.value;
    } else {
      address = sAction.dataGet(data.prefix + "/fields/billing_address_country/value");
    }

    const updateFieldApiValue = (fieldName, fieldValue) => {
      if (sAction.dataGet(data.prefix + "/fields/"+fieldName) !== undefined) {
        sAction.dsAdd("set", data.prefix + "/fields/"+fieldName+"/def/showDetailApi", fieldValue);
      }
    }

    sAction.dsClear();
    if (!address || address === "CZECH REPUBLIC") {
      //zobrazi ikony
      updateFieldApiValue("name", "ApiName");
      updateFieldApiValue("account_name", "ApiName");
      updateFieldApiValue("ticker_symbol", "ApiICO");
      updateFieldApiValue("sic_code", "ApiDIC");
      updateFieldApiValue("billing_address_street", "ApiStreet");
      updateFieldApiValue("shipping_address_street", "ApiStreet");
      updateFieldApiValue("billing_address_postalcode", "ApiPSC");
      updateFieldApiValue("shipping_address_postalcode", "ApiPSC");
    }
    else if(address === "SLOVAKIA"){
      //skryje ikony
      updateFieldApiValue("name", "ApiNameSK");
      updateFieldApiValue("account_name", "ApiNameSK");
      updateFieldApiValue("ticker_symbol", "ApiICOSlovakia");
      updateFieldApiValue("sic_code", "");
      updateFieldApiValue("billing_address_street", "ApiStreet");
      updateFieldApiValue("shipping_address_street", "ApiStreet");
      updateFieldApiValue("billing_address_postalcode", "ApiPSC");
      updateFieldApiValue("shipping_address_postalcode", "ApiPSC");
    }
    else{
      //skryje ikony
      updateFieldApiValue("name", "ApiNameOthers");
      updateFieldApiValue("account_name", "");
      updateFieldApiValue("ticker_symbol", "");
      updateFieldApiValue("sic_code", "");
      updateFieldApiValue("billing_address_street", "");
      updateFieldApiValue("shipping_address_street", "");
      updateFieldApiValue("billing_address_postalcode", "");
      updateFieldApiValue("shipping_address_postalcode", "");
    }

    //Skryvani/odkryvani tabu Finstat kdyz je nebo neni zeme Slovensko
    const tabs = sAction.dataGet(data.prefix + "/tabs");
    if (tabs) {
      const finstatIndex = tabs.findIndex(i => i.name === "LBL_DETAILVIEW_PANEL_FINSTAT");
      if (finstatIndex !== -1) {
        if (address !== "SLOVAKIA") {
          sAction.dsAdd("set", data.prefix + "/tabs/" + finstatIndex + "/hidden", true);
        } else {
          sAction.dsAdd("set", data.prefix + "/tabs/" + finstatIndex + "/hidden", false);
        }
      }
    }
    sAction.dsProcess();
  }
}
