import React from 'react'
import PureComponent from '../../pure'
import TextField from "@material-ui/core/TextField";
import sAction from "sAction";

export default class SalesPlanLine extends PureComponent {
    valChange(way, val) {
        sAction.updateDetailCustom("field", {way: way, value: sAction.parseNumber(val)});
    }

    render() {
        const dataEst = this.props.dataEst;
        const dataAmount = this.props.dataAmount;
        const readonly = this.props.readonly;

        const way = this.props.way;
        const index = this.props.index;
        const itway = way + "/lines/estimate/" + index;
        const itwayAmount = way + "/lines/amount/" + index;
        const keyPre = Date.now();

        return (
            <div className="salesPlanLineContainer">
                <div className="salesPlanEstimateLineContainer">
                    <div style={{width: "10%"}} className="salesPlanMonthCol">
                        {sAction.app_strings.dom_cal_month_long[dataEst.month]}
                    </div>
                    <div style={{width: "19%"}}>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={dataEst.meetings_num}
                            autoComplete="of"
                            disabled={readonly}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => this.valChange(itway + "/meetings_num", ev.target.value)}
                            key={keyPre + "_meetings_num"}
                        />
                    </div>
                    <div style={{width: "19%"}}>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={sAction.formatNumber(dataEst.margin)}
                            autoComplete="of"
                            disabled={readonly}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => this.valChange(itway + "/margin", ev.target.value)}
                            key={keyPre + "_margin"}
                        />
                    </div>
                    <div style={{width: "19%"}}>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={sAction.formatNumber(dataEst.amount)}
                            autoComplete="of"
                            disabled={readonly}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => this.valChange(itway + "/amount", ev.target.value)}
                            key={keyPre + "_amount"}
                        />
                    </div>
                </div>
                {dataAmount && <div className="salesPlanAmountLineContainer">
                    <div style={{width: "10%"}} className="salesPlanMonthCol">
                    </div>
                    <div style={{width: "19%"}}>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={dataAmount.meetings_num}
                            autoComplete="of"
                            disabled={true}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => this.valChange(itwayAmount + "/meetings_num", ev.target.value)}
                            key={keyPre + "_meetings_num_amount"}
                        />
                    </div>
                    <div style={{width: "19%"}}>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={sAction.formatNumber(dataAmount.margin)}
                            autoComplete="of"
                            disabled={readonly}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => this.valChange(itwayAmount + "/margin", ev.target.value)}
                            key={keyPre + "_margin_amount"}
                        />
                    </div>
                    <div style={{width: "19%"}}>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            defaultValue={sAction.formatNumber(dataAmount.amount)}
                            autoComplete="of"
                            disabled={true}
                            className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => this.valChange(itwayAmount + "/amount", ev.target.value)}
                            key={keyPre + "_amount_amount"}
                        />
                    </div>
                </div>}
            </div>
        )
    }
}