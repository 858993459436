import React from "react"
import PureComponent from "../../pure"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import  sAction  from "sAction";

import Button from "../../formElements/Button";
import Relate from "../../formElements/Relate";
import RadioGroup from "../../formElements/RadioGroup";

export default class CopyGiftPopup extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      name: "",
      newName: "replace",
      duplicates: "ignore",
      giftId: this.props.data.id
    };
  }

  handleCopy = () => {
    if (!this.state.id) {
      sAction.error("Nevybrali jste žádný dárek");
      return;
    }

    sAction.load();
    sAction.rest.post("copyGifts", this.state, returnData => {
      sAction.popupHide();

      const message = returnData.messages.map((message, i) => "<p>" + message + "</p>").join("");
      sAction.confrim(message, () => {
        sAction.popupHide();
        window.location.hash = "#detail/acm_GiftsStock/" + this.state.id;
      });

      sAction.unLoad();
    });
  };

  openList = () => {
    sAction.openRelatePopup({module: "acm_GiftsStock"}, returnData => {
      this.handleChange(returnData);
      sAction.popupHide();
    });
  };

  handleChange = returnData => {
    this.setState({
      id: returnData.id,
      name: returnData.name
    });
  };

  render() {
    return (
      <div className="email__popup">
        <div className="acmPopupHeader">Kopírovat seznam dárků</div>
        <div className="viewActionPanel borderBottom">
          <div className="viewActionPanelButtons">
            <Button className="hoverGreen" onClick={this.handleCopy}>
              <div className="actionPanelButtonIcon icon-copy"/>
              Kopírovat
            </Button>
            <div className="viewPanelButtonsDelimiter"/>
            <Button className="hoverRed" onClick={() => sAction.popupHide()}>
              <div className="actionPanelButtonIcon icon-deleteIcon"/>
              {sAction.translate('LBL_CANCEL', 'Home')} {/* Zrušit */}
            </Button>
          </div>
        </div>

        <div className="email__popup__items">
          <DialogContent style={{height: 350}}>
            <DialogContentText/>

            <div className="email__compose__field-row">
              <div className="email__compose__field-row__input">
                <Relate
                  key={this.state.id}
                  newRecord={false}
                  data={{
                    value: this.state.name,
                    label: "Cílový dárek pro nakopírování seznamu"
                  }}
                  buttons={[]}
                  module={"acm_GiftsStock"}
                  callback={this.handleChange}
                />
              </div>
              <div className="email__compose__field-row__button">
                <Button onClick={this.openList} className="stillHover">
                  {/*Vybrat*/}
                  {sAction.translate("LBL_EMAIL_SELECT")}
                </Button>
              </div>
            </div>
            <br/>
            <b>Jméno nakopírovaných dárků:</b><br/>
            <RadioGroup
              value={this.state.newName}
              options={{
                replace: "Nahradit dle cílového dárku",
                keep: "Zachovat původní",
              }}
              onChange={e => this.setState({newName: e.target.value})}
              style={{
                display: "flex",
                flexWrap: "nowrap",
                flexDirection: "row"
              }}
            />
            <br/>
            <b>Případné duplicity / již přidané dárky:</b><br/>
            <RadioGroup
              value={this.state.duplicates}
              options={{
                ignore: "Přeskočit",
                replace: "Nahradit aktuálními daty",
              }}
              onChange={e => this.setState({duplicates: e.target.value})}
              style={{
                display: "flex",
                flexWrap: "nowrap",
                flexDirection: "row"
              }}
            />

          </DialogContent>
        </div>
      </div>
    );
  }
}
