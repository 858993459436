export default function parseDefaultFilter(defaultFilter, addCustomFilter = null){
    // prevede default filter na filter format pro backed 
    // + přidá custom filter addCustomFilter ve filter formatu pro backed
    let actDefaultFilter = {
        operator: "and",
        operands: [],
    }

    if(defaultFilter){
        let withOrGroup = defaultFilter.filter(item => item.hasOwnProperty('orGroup'))
        let withoutOrGroup = defaultFilter.filter(item => !item.hasOwnProperty('orGroup'))
        withOrGroup.sort((a, b) => a.orGroup - b.orGroup)

        // group orGroups by id into orGroups
        let lastGroupId = null;
        let groupArray = [];
        let logicGroups = [];
        withOrGroup.forEach(item => {
            if(lastGroupId === item.orGroup){
                groupArray.push(item);
            } else {
                if(groupArray.length) logicGroups.push({type: "or", data: groupArray});
                groupArray = [item];
                lastGroupId = item.orGroup;
            }
        })
        if(groupArray.length) logicGroups.push({type: "or", data: groupArray});
        logicGroups.push({type: "and", data: withoutOrGroup});

        logicGroups.forEach(group => {
        let logicFilter = {
            operator: group.type,
            operands: [],
        }
        if(withOrGroup.length === 0){
            logicFilter = actDefaultFilter;
        }

        group.data.forEachObject(e => {
            const filter = e;
            if(filter.operandType == "relate" && filter.parentId){
                const operand = {
                    module:filter.module,
                    type:"relate",
                    relationship:filter.relationship,
                    name:filter.name,
                    filter:{
                        operator:"and",
                        operands:[
                            {
                                field:"id", 
                                type:filter.type, 
                                value:filter.parentId
                            }
                        ]
                    }
                }
                logicFilter.operands.push(operand);
            } else if(filter.operandType != "relate"){
                try{
                    const operand = {
                        field:filter.get("field"),
                        type:filter.get("type"),
                        value:filter.get("value"),
                    }
                    logicFilter.operands.push(operand);
                } catch(error){
                    const operand = {
                        field:filter.field,
                        type:filter.type,
                        value:filter.value,
                    }
                    logicFilter.operands.push(operand);
                }
            }
        })
            if(withOrGroup.length === 0){
                actDefaultFilter = logicFilter;
            } else {
                actDefaultFilter.operands.push(logicFilter);
            }
        })
    }

    if (addCustomFilter){
        actDefaultFilter.operands.push(addCustomFilter);
    }

    return actDefaultFilter;
} 