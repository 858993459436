import React from "react";
import PureComponent from "../pure";
import CustomReport from '../CustomReport/Report'

import    sAction    from "sAction";

export default class DetailDashboard extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            dashboardVersion: "",
            init: true
        }

        let dashboardVersion = sAction.dataGet("view/customData/dashboardVersion")
        if (dashboardVersion) this.dashboardVersion = dashboardVersion
    }

    updateDashboard = (data, init) => {
        if (data && (!init || init && this.state.init)){
            this.setState({ dashboardVersion: data })
        }
        
        this.state.init = false;
    }

    render() {
        const {data,id,module,way} = this.props;
        var renderElement = null;

        if (data.get("type") === "customReport") {
            renderElement = <CustomReport widget={true} data={data} way={way} reportId={data.get("reportId")} callback={this.updateDashboard} dashboardVersion={this.state.dashboardVersion} paramData={{recordId:id,isDashboard:true}} />
        }

        return (
            <div className={"detailDashboardContainer" + (this.state.dashboardVersion === "all" ? " dualHeight" : "")}> 
            
                {renderElement}
            </div>
        );
    }
}
