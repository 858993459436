export default function getProductTemplate(id, type, way, index = null) {
    this.load();
    const getFields = ['id', 'mj', 'name', 'cost_price', 'list_price', 'discount_price', 'currency_id', 'description', 'product_type', 'percents', 'inherit', 'picture', 'category_id', 'opce_c', 'dropshipment'];
    const data = {
        id: id,
        getFields: getFields,
    };
    const fullWay = index !== null ? way + '/' + index : way;
    this.rest.post('getProductTemplate', data, returnData => {
        if (returnData['status']) {
            var line = returnData['product'];
            // Chtelo by to dodelat prepocet men z produktu
            // let kurz = this.dataGet(way.split('customData')[0] + 'fields/price_cur/value');
            //const currency_id = sAction.dataGet(way.split("/productData/", 1)[0]+"/currency_id");//TTT cesta natvrdo
            // const prefix = way.split("/")[0];
            const prefix = way.split("/customData/")[0];

            const currency_id = this.dataGet(prefix+"/fields/currency_id/value");
            // let convRate = sAction.dataGet(prefix+"/fields/kurz/value");

            line.cost_price = this.currencyConvert(line.cost_price, line.currency_id, currency_id/*, convRate*/);
            line.list_price = this.currencyConvert(line.list_price, line.currency_id, currency_id/*, convRate*/);
            line.discount_amount = this.currencyConvert(line.discount_amount, line.currency_id, currency_id/*, convRate*/);

            if (type != 'prodName') {
                line.product_template_id = line.id;
                line.id = '';
            }
            // if (line.currency_id !== 'b8326c50-6f19-063b-507d-5e7cc12d5619') {
            //     line.czk_price = line.list_price;
            //     line.list_price = 0;
            // } else {
            //     line.czk_price = line.list_price * kurz;
            // }
            this.updateDetailCustom(type, { way: fullWay, item: line, template: true });
            if (returnData['bound']) {
                if (returnData['bound']['required']) {
                    returnData['bound']['required'].forEach((item) => {
                        item.cost_price = this.currencyConvert(item.cost_price, item.currency_id, currency_id);
                        item.list_price = this.currencyConvert(item.list_price, item.currency_id, currency_id);
                        item.product_template_id = item.id;
                        item.id = '';
                        // if (item.currency_id !== 'b8326c50-6f19-063b-507d-5e7cc12d5619') {
                        //     item.czk_price = item.list_price;
                        //     item.list_price = 0;
                        // } else {
                        //     item.czk_price = item.list_price * kurz;
                        // }
                        this.updateDetailCustom("addProdLine", {way: way, item: item});
                    });
                }
                if (returnData['bound']['other']) {
                    let groupWay = way.split('groups')[0] + 'groups';
                    let groupID = this.dataGet(groupWay, null, true).length;
                    this.updateDetailCustom("addProdGroup", {way: groupWay, name: 'Další specifikace'});
                    returnData['bound']['other'].forEach((item) => {
                        item.cost_price = this.currencyConvert(item.cost_price, item.currency_id, currency_id);
                        item.list_price = this.currencyConvert(item.list_price, item.currency_id, currency_id);
                        item.product_template_id = item.id;
                        item.id = '';
                        // if (item.currency_id !== 'b8326c50-6f19-063b-507d-5e7cc12d5619') {
                        //     item.czk_price = item.list_price;
                        //     item.list_price = 0;
                        // } else {
                        //     item.czk_price = item.list_price * kurz;
                        // }
                        this.updateDetailCustom("addProdLine", {way: groupWay + '/' + groupID + '/lines', item: item})
                    });
                }
                if (returnData['bound']['possible']) {
                    let groupWay = way.split('groups')[0] + 'groups';
                    let groupID = this.dataGet(groupWay, null, true).length;
                    this.updateDetailCustom("addProdGroup", {way: groupWay, name: 'Možné příplatky'});
                    returnData['bound']['possible'].forEach((item) => {
                        item.cost_price = this.currencyConvert(item.cost_price, item.currency_id, currency_id);
                        item.list_price = this.currencyConvert(item.list_price, item.currency_id, currency_id);
                        item.product_template_id = item.id;
                        item.id = '';
                        // if (item.currency_id !== 'b8326c50-6f19-063b-507d-5e7cc12d5619') {
                        //     item.czk_price = item.list_price;
                        //     item.list_price = 0;
                        // } else {
                        //     item.czk_price = item.list_price * kurz;
                        // }
                        this.updateDetailCustom("addProdLine", {way: groupWay + '/' + groupID + '/lines', item: item});
                    });
                }
            }
        }
        this.unLoad();
    });
}
