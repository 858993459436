import detailDefault from "../../detailDefault";
import $ from "jquery";

export default class detailTasks extends detailDefault {
    load(sAction, data) {
        sAction.dsClear();
        sAction.dsAdd('set', `${data.prefix}/fields/date_recidivism_end/def/visible`, true);
        sAction.dsAdd('set', `${data.prefix}/fields/recidivism_parent_id_c/def/visible`, false);
        sAction.dsAdd('set', `${data.prefix}/fields/task_id1_c/def/visible`, false);
        sAction.dsProcess();
        this.updateVisibilityFields(sAction, data);
        this.setRecidivism(sAction, data);
    }

    update(sAction, data) {
        switch (data.field) {
            case 'recidivism':
                this.setRecidivism(sAction, data);
                break;
            case 'repetitions_till':
                this.updateVisibilityFields(sAction, data)
                break;
            case 'recidivism_by':
                this.updateDaysVisibility(sAction, data);
                break;
        }
    }

    beforeSave(sAction, data) {
        let recidivism = sAction.dataGet(data.prefix + '/fields/recidivism_parent_id_c/value');
        if (!recidivism) {
            return true;
        } else {
            sAction.popup('SelectSaveTask', data);
            return false;
        }
    }

    updateVisibilityFields(sAction, data) {
        let repetitions_till = sAction.dataGet(data.prefix + '/fields/repetitions_till/value');
        switch (repetitions_till) {
            case 'ByDate':
                sAction.dsClear();
                sAction.dsAdd('set', `${data.prefix}/fields/date_recidivism_end/def/visible`, true);
                sAction.dsAdd('set', `${data.prefix}/fields/recidivism_amount/def/visible`, false);
                sAction.dsProcess();
                break;
            case 'ByNumber':
                sAction.dsClear();
                sAction.dsAdd('set', `${data.prefix}/fields/date_recidivism_end/def/visible`, false);
                sAction.dsAdd('set', `${data.prefix}/fields/recidivism_amount/def/visible`, true);
                sAction.dsProcess();
                break;
            case 'Inf':
            default:
                sAction.dsClear();
                sAction.dsAdd('set', `${data.prefix}/fields/date_recidivism_end/def/visible`, false);
                sAction.dsAdd('set', `${data.prefix}/fields/recidivism_amount/def/visible`, false);
                sAction.dsProcess();
                break;
        }
    }

    setRecidivism(sAction, data) {
        let recidivism = sAction.dataGet(data.prefix + '/fields/recidivism/value')
        if (recidivism == 1) {
            sAction.dsClear();
            sAction.dsAdd('set', `${data.prefix}/fields/recidivism_by/def/visible`, true);
            sAction.dsAdd('set', `${data.prefix}/fields/recidivism_period/def/visible`, true);
            sAction.dsAdd('set', `${data.prefix}/fields/repetitions_till/def/visible`, true);
            sAction.dsAdd('set', `${data.prefix}/fields/recidivism_days/def/visible`, true);
            sAction.dsProcess();
            this.updateVisibilityFields(sAction, data);
            this.updateDaysVisibility(sAction, data);
        } else {
            sAction.dsClear();
            sAction.dsAdd('set', `${data.prefix}/fields/recidivism_amount/def/visible`, false);
            sAction.dsAdd('set', `${data.prefix}/fields/date_recidivism_end/def/visible`, false);
            sAction.dsAdd('set', `${data.prefix}/fields/recidivism_by/def/visible`, false);
            sAction.dsAdd('set', `${data.prefix}/fields/recidivism_period/def/visible`, false);
            sAction.dsAdd('set', `${data.prefix}/fields/repetitions_till/def/visible`, false);
            sAction.dsAdd('set', `${data.prefix}/fields/recidivism_days/def/visible`, false);
            sAction.dsProcess();
        }
    }

    updateDaysVisibility(sAction, data) {
        let recidivism_by = sAction.dataGet(data.prefix + '/fields/recidivism_by/value');
        if (recidivism_by === 'week') {
            $("#recidivism_days").closest(".detailViewTabContentRow").css("display", "flex");
        } else {
            $("#recidivism_days").closest(".detailViewTabContentRow").css("display", "none");
        }
    }
}
