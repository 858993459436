import React from "react";
import PureComponent from "../../pure";
import sAction from "sAction";
import List from "../../list/list";
import TabContainer from "../../formElements/TabContainer";
import Loader from "../../loader";
import Relate from "../../formElements/Relate";
import Button from "../../formElements/Button";
import AcmDate from "../../formElements/AcmDate";

import GiftsCommon from "../acm_gifts/giftsCommon"

export default class AddGiftDetailPopup extends PureComponent {
    constructor(props) {
        super(props);
        const { id, name } = sAction.dataGet("conf/user");

        this.state = {
            module: this.props.data.get("module"),
            id: this.props.data.get("id"),
            note: "",
            count: 1,
            user_id: id,
            user_name: name,

            gifts: [],
            selected: [],

            date: new Date(),

            data: 0,
            activeTab: 0,

            giftType: "giftPlan"
        };

        this.giftsCommon = new GiftsCommon(this);
    }

    componentDidMount() {
        this.giftsCommon.loadListView("acm_gifts_planning");
        this.giftsCommon.loadListView("acm_GiftsStock");
        this.giftsCommon.loadListView("acm_CampaignsPartipants");
    }

    handleRelateUser = (returnData) => {
        this.setState({
            user_id: returnData.id,
            user_name: returnData.name,
        });
    };

    handleChange = ({ target: { name, value } }) => {
        if (name === "count" && value < 1) return;
        this.setState({ [name]: value });
    };

    changeActiveTab = (e, newTab) => {
        if (newTab === this.state.activeTab) {
            return;
        } else if (newTab > 1 && this.state.gifts.length == 0) {
            return;
        } else if (newTab > 2 && this.state.participants.length == 0) {
            return;
        }

        this.setState({
            activeTab: newTab,
            activeModules: [],
        });
    };

    callback = (data, tab, type, key) => {
        if (typeof data.id === "string") {
            data = {
                id: [data.id],
            };
        }
        this.setState({ 
            [key]: data.id,
            activeTab: tab,
            giftType: type ? type : this.state.giftType
        });
    };

    render() {
        const { data, date, activeTab } = this.state;
        if (data < 3) return <Loader />;
        var id = "";

        let render = "";
        switch (activeTab) {
            case 0:
                render = (
                    <List
                        way={"conf/popup" + id + "/data/listViewacm_gifts_planning"}
                        // key="popupList"
                        data={this.props.data.get("listViewacm_gifts_planning")}
                        categories={null}
                        selectCallBack={(data) => { this.callback(data, 2, "giftPlan", "gifts") }}
                        module={"acm_gifts_planning"}
                    />
                );
                break;

            case 1:
                render = (
                    <List
                        way={"conf/popup" + id + "/data/listViewacm_GiftsStock"}
                        // key="popupList"
                        data={this.props.data.get("listViewacm_GiftsStock")}
                        categories={null}
                        selectCallBack={(data) => { this.callback(data, 2, "giftWP", "gifts") }}
                        module={"acm_GiftsStock"}
                    />
                );
                break;
            case 2:
                render = (
                    <List
                        way={"conf/popup" + id + "/data/listViewacm_CampaignsPartipants"}
                        // key="popupList"
                        data={this.props.data.get("listViewacm_CampaignsPartipants")}
                        categories={null}
                        selectCallBack={(data) => { this.callback(data, 3, null, "selected") }}
                        module={"acm_CampaignsPartipants"}
                    />
                );
                break;

            case 3:
                render = (
                    <>
                        <div key={activeTab} className="addGiftPopup">
                            <div className="pageContainer ListViewContainer">
                                <div className="giftPopupBox">
                                    <div className="addGiftPopupGrid">
                                        <div className="lineGrid">
                                            <div className="inputLabel" ><p>{sAction.translate("LBL_ACTION", "acm_CampaignsAction")}</p></div>
                                            <div className="inputField text">
                                                <p>{this.props.data.get("name")}</p>
                                            </div>
                                        </div>

                                        <div className="lineGrid">
                                            <div className="inputLabel" ><p>{sAction.translate("LBL_GIFTS_CHOOSED", "acm_CampaignsAction")}</p></div>
                                            <div className="inputField text">
                                                <p>{this.state.gifts === "all" ? sAction.translate("LBL_ALL") : this.state.gifts.length}</p>
                                            </div>
                                        </div>

                                        <div className="lineGrid">
                                            <div className="inputLabel" ><p>{sAction.translate("LBL_PARTICIPANTS_CHOOSED", "acm_CampaignsAction")}</p></div>
                                            <div className="inputField text"> <p>
                                                {this.state.selected[0] == "all"
                                                    ? sAction.translate("LBL_ALL")
                                                    : this.state.selected.length}
                                            </p> </div>
                                        </div>

                                        <div className="lineGrid">
                                            <div className="inputLabel" ><p>{sAction.translate("LBL_HANDOVER_DATE", "acm_CampaignsAction")}</p></div>
                                            <div className="inputField AcmDate">
                                                <AcmDate
                                                    autoOpen={false}
                                                    value={new Date()}
                                                    onChange={(date) => this.setState({ date })}
                                                />
                                            </div>
                                        </div>

                                        <div className="lineGrid">
                                            <div className="inputLabel" ><p>{sAction.translate("LBL_GIVER", "acm_CampaignsAction")}</p></div>
                                            <div className="DetailViewValue inputField" style={{ width: "100%" }}>
                                                <Relate
                                                    key={this.state.user_id}
                                                    newRecord={false}
                                                    data={{
                                                        value: this.state.user_name,
                                                    }}
                                                    module={"Users"}
                                                    callback={this.handleRelateUser}
                                                />
                                            </div>
                                        </div>

                                        <div className="lineGrid">
                                            <div className="inputLabel" ><p>{sAction.translate("LBL_COUNT", "acm_CampaignsAction")}</p></div>
                                            <div className="inputField">
                                                <input className="input" type="number" value={this.state.count} name="count" onChange={this.handleChange} />
                                            </div>
                                        </div>

                                        <div className="lineGrid">
                                            <div className="inputLabel" ><p>{sAction.translate("LBL_NOTE", "acm_CampaignsAction")}</p></div>
                                            <div className="inputField">
                                                <textarea className="textarea" name="note" onChange={this.handleChange} value={this.state.note} />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="acmPopupButtons gridButtonBox" style={{ justifyContent: "flex-start" }}>
                                        <Button className="hoverGreen" onClick={this.giftsCommon.savePopup}>
                                            <div className="actionPanelButtonIcon icon-saveIcon" /> {sAction.translate("LBL_ADD_GIFT")}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
                break;
        }

        return (
            <React.Fragment>
                <div className="acmPopupHeader">{sAction.translate("LBL_ADD_GIFT")}</div>
                <div className="borderBottom">
                    <TabContainer
                        value={activeTab}
                        onChange={this.changeActiveTab}
                        tabs={[
                            sAction.translate("LBL_CHOOSE_GIFT_PLAN", "acm_CampaignsAction"),
                            sAction.translate("LBL_CHOOSE_GIFT", "acm_CampaignsAction"),
                            sAction.translate("LBL_CHOOSE_PARTICIPANTS", "acm_CampaignsAction"),
                            sAction.translate("LBL_SUMMARY", "acm_CampaignsAction"),
                        ]}
                    />
                </div>
                {render}
            </React.Fragment>
        );
    }
}
