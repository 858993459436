import detailDefault from "../../detailDefault"

export default class detailacm_orders extends detailDefault {
    load(sAction, data) {
    }

    update(sAction, data) {
    }

    updateDetailLine(sAction, data) {
        switch (data.field) {

            case 'status':
                if (data.value) {
                    console.log(data);
                    const lines=sAction.dataGet("view/customData/customLines/lines");
            
             
                    let state = "new";
                    let invoicedFlag=false;
                    for(let i=0 ; i<lines.size; i++){
                        //get = lines?.get?.(i)?.get?.('status');
                        if(lines?.get?.(i)?.get?.('status') !=='invoiced')
                            invoicedFlag=true;
                        if ( lines?.get?.(i)?.get?.('status') === 'delivered') {
                            state = "partially_completed";
                        }           
                    }
                    console.log(state);
                    state= invoicedFlag ? state : "completed";
                    sAction.dataSet("view/fields/stav/value",state);
                   
                }

            case 'name':
                if (data.value.id) {
                    sAction.load();
                    let fields = {cena_bez_dph_kus: 'list_price'};
                    const searchData = {
                        module: 'ProductTemplates',
                        fields: ['id'],
                        getFields: Object.values(fields),
                        value: data.value.id,
                        limit: 999999,
                    };

                    sAction.quickSearch(searchData, returnData => {
                        sAction.dsClear();
                        fields.forEachObject((templateName, lineName) => {
                            data.saveField(returnData[0][templateName], lineName, true);
                        });
                        sAction.dsProcess();
                        sAction.unLoad();
                    });
                }
                break;
        }
    }
}
