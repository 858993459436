import detailDefault from "../../detailDefault";
/**
 *  Dárky wiki https://gitlab.acmark.cz/coripo/coripo/-/wikis/funkcionality-a-procesy/nove-darky
 */
export default class detailacm_Gifts extends detailDefault {
    load(sAction, data) {
        this.checkWithoutPlanSW(sAction, data);
        const userId = sAction.dataGet("conf/user/id");

        const defaultFilter = [
            {
                module: "acm_gifts_planning",
                field: "assigned_user_id",
                value: userId,
                type: "eq",
            },
            {
                module: "acm_gifts_planning",
                field: "darku_zbyva",
                value: 0,
                type: "moreThan",
            },
        // orGroup id: 1
            {   
                module: "acm_gifts_planning",
                field: "date_valid",
                type: "moreThanInclude",
                value: "{%DATE_TODAY%}",
                orGroup: 1
            },
            {
                module: "acm_gifts_planning",
                field: "date_valid",
                type: "null",
                value: null,
                orGroup: 1
            },
        ];

        const defaultFilterGifts = [
            {
                module: "acm_GiftsStock",
                field: "without_planing",
                value: "1",
                type: "eq",
            },
            {
                module: "acm_GiftsStock",
                field: "active",
                value: "1",
                type: "eq",
            },
            {
                module: "acm_GiftsStock",
                field: "darku_celkem",
                value: 0,
                type: "moreThan",
            },
        // orGroup id: 1
            {
                module: "acm_gifts_planning",
                field: "datum_zamknuti",
                type: "moreThanInclude",
                value: "{%DATE_TODAY%}",
                orGroup: 1
            },
            {
                module: "acm_gifts_planning",
                field: "datum_zamknuti",
                type: "null",
                value: null,
                orGroup: 1
            }
        ];

        sAction.dsClear()
        sAction.dsAdd("set", data.prefix + "/fields/acm_gifts_acm_giftsstock_name/def/defaultFilter", defaultFilterGifts);
        sAction.dsAdd("set", data.prefix + "/fields/acm_gifts_planning_acm_gifts_1_name/def/defaultFilter", defaultFilter);
        sAction.dsProcess();
    }

    update(sAction, data) {
        switch (data.field) {
            case "acm_gifts_contactscontacts_ida":
                if(data.value.id){
                const params = {
                    module : "Contacts",
                    id : data.value.id,
                    action: "getAccountInfo"
                }
                sAction.load();
                sAction.rest.post("customAction", params, function(resp) {
                sAction.unLoad();
                    if (resp.errCode == "OK") {
                        const fieldData = {
                            "way": data.prefix+"/fields/accounts_acm_gifts_1_name",
                            "name": "accounts_acm_gifts_1accounts_ida",
                            "fieldName": "accounts_acm_gifts_1_name",
                            "type": "relate",
                            "value": {
                                "id": resp.id,
                                "name": resp.name
                            }
                        }
                        sAction.saveField(fieldData, true);
                    }
                }, false);
                }
                break;
            case "count":
            case "assigned_user_id":
            case "datum_predani":
                this.checkAvailability(sAction, data);
                break;
            case "without_planning":
                if(!this.withoutPlaneSW(sAction, data)){
                    sAction.dsClear();
                    sAction.dsAdd("set", data.prefix + "/fields/acm_gifts_acm_giftsstockacm_giftsstock_ida/value", null)
                    sAction.dsAdd("set", data.prefix + "/changes/fields/acm_gifts_acm_giftsstockacm_giftsstock_ida", null)
                    sAction.dsAdd("set", data.prefix + "/fields/acm_gifts_acm_giftsstock_name/def/id_value", null)
                    sAction.dsAdd("set", data.prefix + "/fields/acm_gifts_acm_giftsstock_name/value", "")
                    sAction.dsAdd("set", data.prefix + "/changes/fields/acm_gifts_acm_giftsstock_name", null)
                    sAction.dsAdd("set", data.prefix + "/fields/acm_gifts_planning_acm_gifts_1_name/value", "");
                    sAction.dsProcess();
                }
                this.checkWithoutPlanSW(sAction, data);
                this.checkAvailability(sAction, data);
                break;
            case "acm_gifts_acm_giftsstockacm_giftsstock_ida":
                this.checkAvailability(sAction, data);
                const name = sAction.dataGet( data.prefix + "/fields/name/value");
                let newName = sAction.dataGet( data.prefix + "/fields/acm_gifts_acm_giftsstock_name/value");
                if(name && name.length !== 0){
                    newName = name.split(" - ")[0] + " - " + newName;
                }

                sAction.dsClear();
                sAction.dsAdd("set", data.prefix + "/name", newName)
                sAction.dsAdd("set", data.prefix + "/fields/name/value", newName)
                sAction.dsAdd("set", data.prefix + "/changes/fields/name", newName)
                sAction.dsProcess();
                break;
            case "acm_gifts_planning_acm_gifts_1acm_gifts_planning_ida":
                const giftPlanId = sAction.dataGet( data.prefix + "/fields/acm_gifts_planning_acm_gifts_1_name/def/id_value");
                this.checkAvailability(sAction, data);

                if(!giftPlanId || this.withoutPlaneSW(sAction, data)){
                    break;
                }
                sAction.load();
                sAction.rest.post("getGiftFromPlan", {id: giftPlanId}, (resp) => {
                    sAction.unLoad();
                    if(!resp.status){
                        sAction.error("Nastala chyba");
                        return;
                    }

                    sAction.dsClear();
                    sAction.dsAdd("set", data.prefix + "/fields/acm_gifts_acm_giftsstockacm_giftsstock_ida/value", resp.message.data.id)
                    sAction.dsAdd("set", data.prefix + "/changes/fields/acm_gifts_acm_giftsstockacm_giftsstock_ida", resp.message.data.id)
                    sAction.dsAdd("set", data.prefix + "/fields/acm_gifts_acm_giftsstock_name/def/id_value", resp.message.data.id)
                    sAction.dsAdd("set", data.prefix + "/fields/acm_gifts_acm_giftsstock_name/value", resp.message.data.name)
                    sAction.dsAdd("set", data.prefix + "/changes/fields/acm_gifts_acm_giftsstock_name", resp.message.data.name)

                    const name = sAction.dataGet( data.prefix + "/fields/name/value");
                    let newName = resp.message.data.name;
                    if(name && name.length !== 0){
                        newName = name.split(" - ")[0] + " - " + resp.message.data.name;
                    }
                   
                    sAction.dsAdd("set", data.prefix + "/name", newName)
                    sAction.dsAdd("set", data.prefix + "/fields/name/value", newName)
                    sAction.dsAdd("set", data.prefix + "/changes/fields/name", newName)
                    sAction.dsProcess();
                }, false);
                break;
        }
    }

    withoutPlaneSW = (sAction, data) => {
        const getWithoutPlanSwitch = sAction.dataGet( data.prefix + "/fields/without_planning/value");
        return (getWithoutPlanSwitch === 1 || getWithoutPlanSwitch === '1')
    }

    checkWithoutPlanSW = (sAction, data) => {
        sAction.dsClear();
        if(this.withoutPlaneSW(sAction, data)){
            sAction.dsAdd("set", data.prefix + "/fields/acm_gifts_planning_acm_gifts_1_name/def/required", false);
            sAction.dsAdd("set", data.prefix + "/fields/acm_gifts_planning_acm_gifts_1_name/def/readonly", true);
            sAction.dsAdd("set", data.prefix + "/fields/acm_gifts_acm_giftsstock_name/def/required", true);
            sAction.dsAdd("set", data.prefix + "/fields/acm_gifts_acm_giftsstock_name/def/readonly", false);
        } else {
            sAction.dsAdd("set", data.prefix + "/fields/acm_gifts_planning_acm_gifts_1_name/def/required", true);
            sAction.dsAdd("set", data.prefix + "/fields/acm_gifts_planning_acm_gifts_1_name/def/readonly", false);
            sAction.dsAdd("set", data.prefix + "/fields/acm_gifts_acm_giftsstock_name/def/required", false);
            sAction.dsAdd("set", data.prefix + "/fields/acm_gifts_acm_giftsstock_name/def/readonly", true);
        }
        sAction.dsProcess();
    }

    checkAvailability = (sAction, data) => {
        const giftId = sAction.dataGet( data.prefix + "/fields/acm_gifts_acm_giftsstock_name/def/id_value");
        const giftPlanId = sAction.dataGet( data.prefix + "/fields/acm_gifts_planning_acm_gifts_1_name/def/id_value");
        const count = sAction.dataGet( data.prefix + "/fields/count/value");
        const user_id = sAction.dataGet( data.prefix + "/fields/assigned_user_name/def/id_value");
        const date = sAction.dataGet( data.prefix + "/fields/datum_predani/value");

        if(this.withoutPlaneSW(sAction, data)){
            if(!giftId) return;
        } else {
            if(!giftPlanId) return;
        }

        sAction.load();
        sAction.rest.post("checkGiftAvailability", {
            plan_id: giftPlanId,
            gift_id: giftId,
            count: count,
            user_id: user_id,
            date: date,
            withoutPlanSwitch: this.withoutPlaneSW(sAction, data)
        }, (resp) => {
            sAction.unLoad();
            if(resp.status == false){
                sAction.error(resp.errorMessage.text);
                sAction.setStorage("canSave", false);
            } else {
                sAction.setStorage("canSave", true);
                sAction.dsClear();
                sAction.dsAdd("set", data.prefix + "/fields/price/value", resp.message.data.price)
                sAction.dsAdd("set", data.prefix + "/changes/fields/price", resp.message.data.price)
                sAction.dsProcess();
            }
        }, false);
    }

    beforeSave(sAction, data) {
        if (sAction.getStorage("canSave")) {
            return true;
        }

        this.checkAvailability(sAction, data);
    }
}