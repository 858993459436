/**
 *  Dárky wiki https://gitlab.acmark.cz/coripo/coripo/-/wikis/funkcionality-a-procesy/nove-darky
 */
export default class GiftsCommon {
    constructor(parent) {
        this.parent = parent;
    }

    loadListView = (module) => {
        const restData = {
            limit: 25,
            offset: 0,
            order: [
                {
                    module,
                    field: "date_entered",
                    sort: "asc",
                },
            ],
            filter: {},
        };

        let defaultFilter = null;
        const userId = sAction.dataGet("conf/user/id");

        if (module === "acm_CampaignsPartipants") {
            restData.filter = {
                operator: "and",
                operands: [
                    {
                        module: "acm_CampaignsAction",
                        type: "relate",
                        relationship: ["acm_campaignsaction_acm_campaignspartipants"],
                        name: "acm_campaignsaction_acm_campaignspartipants_name",
                        filter: {
                            operator: "and",
                            operands: [
                                {
                                    field: "id",
                                    type: "eq",
                                    value: this.parent.state.id,
                                }
                            ],
                        },
                    },
                    {
                        "operator": "and",
                        "operands": [
                            {
                                "field": "acm_campaignspartipants_contacts_name",
                                "fieldModule": null,
                                "fieldRel": null,
                                "type": "nnull",
                                "value": null
                            }
                        ]
                    }
                ],
            };

        } else if (module === "acm_GiftsStock") {
            restData.filter = {
                operator: "and",
                operands: [
                    {
                        "operator": "and",
                        "operands": [
                            {
                                "field": "without_planing",
                                "fieldModule": 'acm_GiftsStock',
                                "fieldRel": null,
                                "type": "eq",
                                "value": '1'
                            },
                            {
                                "field": "active",
                                "fieldModule": 'acm_GiftsStock',
                                "fieldRel": null,
                                "type": "eq",
                                "value": '1'
                            },
                            {
                                "field": "darku_celkem",
                                "fieldModule": 'acm_GiftsStock',
                                "fieldRel": null,
                                "type": "moreThan",
                                "value": '0'
                            },
                            {
                                "operator": "or",
                                "operands": [
                                    {
                                        "field": "datum_zamknuti",
                                        "type": "moreThanInclude",
                                        "value": "{%DATE_TODAY%}"
                                    },
                                    {
                                        "field": "datum_zamknuti",
                                        "type": "null",
                                        "value": null
                                    }
                                ]
                            },
                        ]
                    }
                ],
            };
        } else if (module === "acm_gifts_planning") {
            restData.filter = {
                operator: "and",
                operands: [
                    {
                        "operator": "and",
                        "operands": [
                            {
                                "field": "assigned_user_id",
                                "fieldModule": 'acm_gifts_planning',
                                "fieldRel": null,
                                "type": "eq",
                                "value": userId
                            },
                            {
                                "field": "darku_zbyva",
                                "fieldModule": 'acm_gifts_planning',
                                "fieldRel": null,
                                "type": "moreThan",
                                "value": 0
                            }
                        ]
                    },
                    {
                        "operator": "or",
                        "operands": [
                            {
                                "field": "date_valid",
                                "type": "moreThanInclude",
                                "value": "{%DATE_TODAY%}"
                            },
                            {
                                "field": "date_valid",
                                "type": "null",
                                "value": null
                            }
                        ]
                    }
                ],
            };
        }

        sAction.rest.post("list/" + module, restData, (data) => {
            const id = "";
            sAction.dsClear();

            var content = sAction.listContent(data);
            var akce = {
                type: "PAGELOADLIST",
                page: module,
                content: content,
                defaultFilter,
                listViewPrefix: "conf/popup" + id + "/data/listView" + module,
                function: this.parent,
            };

            sAction.dsAdd("set", "conf/popup" + id + "/data/listView" + module, {}, "ListView");
            sAction.dsAddCustom(akce);
            sAction.dsAdd("set", "conf/popup" + id + "/data/callback" + module, this.parent.callback);
            sAction.dsAdd("set", "conf/popup" + id + "/data/listView" + module + "/type", "popup");
            sAction.dsProcess();

            this.parent.setState({ data: this.parent.state.data + 1 });
        }, false);

    };

    savePopup = () => {
        sAction.load();

        // filter pro vybrane zaznamy v listview
        let prefix = "conf/popup/data/listViewacm_CampaignsPartipants";
        let records = sAction.dataGet(prefix + "/selected");
        var filterParticipants = null;
        if(records === "all"){
            const list = sAction.dataGet(prefix);
            const savedSearch = list.actSavedSearch;
            filterParticipants = sAction.reloadListParams({
                list,
                prefix,
                savedSearch,
                columns: false,
            });
            filterParticipants["selected"] = list.selected;
            filterParticipants["module"] = list.modul;
            filterParticipants["type"] = list.type;
        }

        prefix = "conf/popup/data/listViewacm_gifts_planning";
        records = sAction.dataGet(prefix + "/selected");

        var filterGifts = null;
        if(records === "all"){
            const list = sAction.dataGet(prefix);
            const savedSearch = list.actSavedSearch;
            filterGifts = sAction.reloadListParams({
                list,
                prefix,
                savedSearch,
                columns: false,
            });
            filterGifts["selected"] = list.selected;
            filterGifts["module"] = list.modul;
            filterGifts["type"] = list.type;
        }

        prefix = "conf/popup/data/listViewacm_GiftsStock";
        records = sAction.dataGet(prefix + "/selected");

        var filterGifts = null;
        if(records === "all"){
            const list = sAction.dataGet(prefix);
            const savedSearch = list.actSavedSearch;
            filterGifts = sAction.reloadListParams({
                list,
                prefix,
                savedSearch,
                columns: false,
            });
            filterGifts["selected"] = list.selected;
            filterGifts["module"] = list.modul;
            filterGifts["type"] = list.type;
        }

        const sendData = {
            ...this.parent.state,
            selected_filter: filterParticipants,
            filter_gifts: filterGifts,
        }

        sAction.rest.post("addGift", sendData, (data) => {
            sAction.popupHide();
            sAction.unLoad();
            
            let text = sAction.translate("LBL_DONE","acm_CampaignsAction") + "<br><br>";
            text += sAction.translate("LBL_GIFTS_ADDED_COUNT", "acm_CampaignsAction") + ": " + (data.added_gifts_count || "0") + "<br>";
            text += sAction.translate("LBL_GIFTS_SKIPPED_COUNT", "acm_CampaignsAction") + ": " + (data.skipped_gifts_count || "0");

            if(this.parent.state.module !== "Contacts"){
                const prefix = this.parent.props.data.get("prefix");
                const subpanelToReload = sAction.dataGet(prefix+"/subpanels").findIndex(s => s.module === "acm_Gifts");
                if (subpanelToReload !== -1) {
                    sAction.alert(text);
                }
            } else {
                sAction.alert(text);
            }
        });
    };

}