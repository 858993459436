import React from "react";
import PureComponent from "../pure";
import  sAction  from "sAction";
import Loader from "../loader";

export default class Report extends PureComponent {
  constructor() {
    super();
    this.state = {
      acmDate:false,
      acmDateValue:null,
    }
    this.iframe = React.createRef();
  }
  componentDidMount(){
    var id = null;
    const way = this.props.way;
    if(this.props.reportId !== undefined){
      id = this.props.reportId
    }else{
      id = window.location.hash.split("/")
      id = id[1];
    }
    var paramData = {};
    if(this.props.paramData != undefined){
      paramData = this.props.paramData;
    }

    paramData["isWidget"] = this.props.widget
    paramData["way"] = this.props.way
    paramData["reportId"] = this.props.reportId
    paramData["widgetId"] = this.props.widgetId
    paramData["widget"] = this.props.widget
    paramData["dashboardVersion"] = this.props.dashboardVersion
    paramData["style"] = sAction.getStorage("style");
    sAction.loadCustomReport(id,way,paramData);
    var self = this;
    this.listener = function(evt){
      self.iframeMessage(evt);
    }
    window.addEventListener("message",this.listener)
  }

  componentDidUpdate(){
    const data = this.props.data

    var html = "";
    try{
      html = data.get("html");
    }catch(e){
      
    }

    this.iframe.current.contentWindow.postMessage(this.props.dashboardVersion, '*');
    
    this.iframe.current.contentWindow.document.open();
    this.iframe.current.contentWindow.document.write(html);

    if (!html){
      var id = null;
      const way = this.props.way;
      if(this.props.reportId !== undefined){
        id = this.props.reportId
      }else{
        id = window.location.hash.split("/")
        id = id[1];
      }

      var paramData = {};
      if(this.props.paramData != undefined){
        paramData = this.props.paramData;
      }

      paramData["isWidget"] = this.props.widget
      paramData["way"] = this.props.way
      paramData["reportId"] = this.props.reportId
      paramData["widgetId"] = this.props.widgetId
      paramData["widget"] = this.props.widget
      paramData["dashboardVersion"] = this.props.dashboardVersion
      paramData["style"] = sAction.toBase64(sAction.getStorage("style"));
      sAction.loadCustomReport(id,way,paramData);
    }
   
  }
  componentWillUnmount(){
    window.removeEventListener("message",this.listener);
  }
  iframeMessage(message){
    var data = message.data
    var id = null;
    if(this.props.reportId !== undefined){
      id = this.props.reportId
    }else{
      id = window.location.hash.split("/")
      id = id[1];
    }
    const way = this.props.way
    
    // vyjimka aby se na home dashboardu neprenacitaly vsechny
    // reporty, ale jen ten, ktery uzivatel odeslal
    if (data.data && data.data.way && data.data.way !== way) {
      return;
    }
    
    if(data.action == "submit"){
      sAction.dataSet(way+"/html","load");
  
      data.data["widgetId"] = this.props.widgetId
      data.data["isWidget"] = this.props.widget

      sAction.loadCustomReport(id,way,data.data);
    }else if(data.action == "href"){
      if(data.target == "_blank"){
        window.open(data.url,'_blank');
      }else{
        window.location = data.url;
      }
    }else if(data.action == "ajax"){
        sAction.customReportAjax(id,data,returnData => {
          console.log(this.iframe);
        this.iframe.current.contentWindow.postMessage(returnData, '*');
      })
    }else if(data.action == "relate"){
      data = {
        module: data.module,
      };
      sAction.openRelatePopup(data, returnData => {
        sAction.popupHide();
        this.iframe.current.contentWindow.postMessage(returnData, '*');
      });
    } else if (data.action == "download") {
      sAction.downloadFile({
        id: "",
        name: data.file,
        module: "acm_coripoReports",
        params: {
          file: data.file
        }
      });
    }else if(data.action == "message"){
      this.reportMessage(data.data,returnData => {
        this.iframe.current.contentWindow.postMessage(returnData, '*');
      });

    }else if(data.action == "setTitle"){
      document.title = data.data;
      document.querySelector(".headerBreadCrumb span").innerHTML = data.data

    } else if (data.action == "accSwitchDashboard") {      
      console.log(data);
      this.props.callback(data.data.company, data.data.init);
    }
  }
    reportMessage(data,callback){
      if(data.action == "acmDate"){
        sAction.popup("acmDate",{value:data.data.value,callback:(date) => {
          callback(date)
        }});
      }
      if(data.action == "acmDateTime"){
        sAction.popup("acmDateTime",{value:data.data.value,callback:(date) => {
          callback(date)
        }});
      }
    }
  render() {
    const data = this.props.data
    var load = false
    if(data == null){
      load = true
    }else{
      if(data.get("html") == "" ||data.get("html") == "load"){
        load = true
      }
    }
    return (
        <div className="customReportContainer">
            <div className="customReport">
              {load == true && <Loader />}
              <iframe style={load == true ? {display:"none"}:null} ref={this.iframe} />
            </div>
        </div>
    );
  }
}
